// tslint:disable
/**
 * Open Hospital API Documentation
 * Open Hospital API Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    PagePatientDTO,
    PatientDTO,
} from '../models';

export interface DeletePatientRequest {
    code: number;
}

export interface GetPatientRequest {
    code: number;
}

export interface GetPatientAllRequest {
    code: number;
}

export interface GetPatientsRequest {
    page?: number;
    size?: number;
}

export interface MergePatientsRequest {
    mergedcode: number;
    code2: number;
}

export interface NewPatientRequest {
    patientDTO: PatientDTO;
}

export interface SearchPatientRequest {
    firstName?: string;
    secondName?: string;
    birthDate?: string;
    address?: string;
}

export interface UpdatePatientRequest {
    code: number;
    patientDTO: PatientDTO;
}

/**
 * no description
 */
export class PatientsApi extends BaseAPI {

    /**
     */
    deletePatient({ code }: DeletePatientRequest): Observable<boolean>
    deletePatient({ code }: DeletePatientRequest, opts?: OperationOpts): Observable<RawAjaxResponse<boolean>>
    deletePatient({ code }: DeletePatientRequest, opts?: OperationOpts): Observable<boolean | RawAjaxResponse<boolean>> {
        throwIfNullOrUndefined(code, 'code', 'deletePatient');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<boolean>({
            url: '/patients/{code}'.replace('{code}', encodeURI(code)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getPatient({ code }: GetPatientRequest): Observable<PatientDTO>
    getPatient({ code }: GetPatientRequest, opts?: OperationOpts): Observable<RawAjaxResponse<PatientDTO>>
    getPatient({ code }: GetPatientRequest, opts?: OperationOpts): Observable<PatientDTO | RawAjaxResponse<PatientDTO>> {
        throwIfNullOrUndefined(code, 'code', 'getPatient');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<PatientDTO>({
            url: '/patients/{code}'.replace('{code}', encodeURI(code)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getPatientAll({ code }: GetPatientAllRequest): Observable<PatientDTO>
    getPatientAll({ code }: GetPatientAllRequest, opts?: OperationOpts): Observable<RawAjaxResponse<PatientDTO>>
    getPatientAll({ code }: GetPatientAllRequest, opts?: OperationOpts): Observable<PatientDTO | RawAjaxResponse<PatientDTO>> {
        throwIfNullOrUndefined(code, 'code', 'getPatientAll');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'code': code,
        };

        return this.request<PatientDTO>({
            url: '/patients/all',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getPatientCities(): Observable<Array<string>>
    getPatientCities(opts?: OperationOpts): Observable<RawAjaxResponse<Array<string>>>
    getPatientCities(opts?: OperationOpts): Observable<Array<string> | RawAjaxResponse<Array<string>>> {
        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<string>>({
            url: '/patients/cities',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getPatientNextCode(): Observable<number>
    getPatientNextCode(opts?: OperationOpts): Observable<RawAjaxResponse<number>>
    getPatientNextCode(opts?: OperationOpts): Observable<number | RawAjaxResponse<number>> {
        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<number>({
            url: '/patients/nextcode',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getPatients({ page, size }: GetPatientsRequest): Observable<PagePatientDTO>
    getPatients({ page, size }: GetPatientsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<PagePatientDTO>>
    getPatients({ page, size }: GetPatientsRequest, opts?: OperationOpts): Observable<PagePatientDTO | RawAjaxResponse<PagePatientDTO>> {

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (page != null) { query['page'] = page; }
        if (size != null) { query['size'] = size; }

        return this.request<PagePatientDTO>({
            url: '/patients',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    mergePatients({ mergedcode, code2 }: MergePatientsRequest): Observable<boolean>
    mergePatients({ mergedcode, code2 }: MergePatientsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<boolean>>
    mergePatients({ mergedcode, code2 }: MergePatientsRequest, opts?: OperationOpts): Observable<boolean | RawAjaxResponse<boolean>> {
        throwIfNullOrUndefined(mergedcode, 'mergedcode', 'mergePatients');
        throwIfNullOrUndefined(code2, 'code2', 'mergePatients');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'mergedcode': mergedcode,
            'code2': code2,
        };

        return this.request<boolean>({
            url: '/patients/merge',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    newPatient({ patientDTO }: NewPatientRequest): Observable<PatientDTO>
    newPatient({ patientDTO }: NewPatientRequest, opts?: OperationOpts): Observable<RawAjaxResponse<PatientDTO>>
    newPatient({ patientDTO }: NewPatientRequest, opts?: OperationOpts): Observable<PatientDTO | RawAjaxResponse<PatientDTO>> {
        throwIfNullOrUndefined(patientDTO, 'patientDTO', 'newPatient');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<PatientDTO>({
            url: '/patients',
            method: 'POST',
            headers,
            body: patientDTO,
        }, opts?.responseOpts);
    };

    /**
     */
    searchPatient({ firstName, secondName, birthDate, address }: SearchPatientRequest): Observable<Array<PatientDTO>>
    searchPatient({ firstName, secondName, birthDate, address }: SearchPatientRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<PatientDTO>>>
    searchPatient({ firstName, secondName, birthDate, address }: SearchPatientRequest, opts?: OperationOpts): Observable<Array<PatientDTO> | RawAjaxResponse<Array<PatientDTO>>> {

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (firstName != null) { query['firstName'] = firstName; }
        if (secondName != null) { query['secondName'] = secondName; }
        if (birthDate != null) { query['birthDate'] = (birthDate as any).toISOString(); }
        if (address != null) { query['address'] = address; }

        return this.request<Array<PatientDTO>>({
            url: '/patients/search',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    updatePatient({ code, patientDTO }: UpdatePatientRequest): Observable<PatientDTO>
    updatePatient({ code, patientDTO }: UpdatePatientRequest, opts?: OperationOpts): Observable<RawAjaxResponse<PatientDTO>>
    updatePatient({ code, patientDTO }: UpdatePatientRequest, opts?: OperationOpts): Observable<PatientDTO | RawAjaxResponse<PatientDTO>> {
        throwIfNullOrUndefined(code, 'code', 'updatePatient');
        throwIfNullOrUndefined(patientDTO, 'patientDTO', 'updatePatient');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<PatientDTO>({
            url: '/patients/{code}'.replace('{code}', encodeURI(code)),
            method: 'PUT',
            headers,
            body: patientDTO,
        }, opts?.responseOpts);
    };

}
