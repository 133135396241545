// tslint:disable
/**
 * Open Hospital API Documentation
 * Open Hospital API Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    MedicalDTO,
} from '../models';

export interface DeleteMedicalRequest {
    code: number;
}

export interface FilterMedicalsRequest {
    desc?: string;
    type?: string;
    critical?: boolean;
    nameSorted?: boolean;
}

export interface GetMedicalRequest {
    code: number;
}

export interface GetMedicalsRequest {
    sortBy?: GetMedicalsSortByEnum;
}

export interface NewMedicalRequest {
    medicalDTO: MedicalDTO;
    ignoreSimilar?: boolean;
}

export interface UpdateMedicalRequest {
    medicalDTO: MedicalDTO;
    ignoreSimilar?: boolean;
}

/**
 * no description
 */
export class MedicalsApi extends BaseAPI {

    /**
     */
    deleteMedical({ code }: DeleteMedicalRequest): Observable<boolean>
    deleteMedical({ code }: DeleteMedicalRequest, opts?: OperationOpts): Observable<RawAjaxResponse<boolean>>
    deleteMedical({ code }: DeleteMedicalRequest, opts?: OperationOpts): Observable<boolean | RawAjaxResponse<boolean>> {
        throwIfNullOrUndefined(code, 'code', 'deleteMedical');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<boolean>({
            url: '/medicals/{code}'.replace('{code}', encodeURI(code)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    filterMedicals({ desc, type, critical, nameSorted }: FilterMedicalsRequest): Observable<Array<MedicalDTO>>
    filterMedicals({ desc, type, critical, nameSorted }: FilterMedicalsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<MedicalDTO>>>
    filterMedicals({ desc, type, critical, nameSorted }: FilterMedicalsRequest, opts?: OperationOpts): Observable<Array<MedicalDTO> | RawAjaxResponse<Array<MedicalDTO>>> {

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (desc != null) { query['desc'] = desc; }
        if (type != null) { query['type'] = type; }
        if (critical != null) { query['critical'] = critical; }
        if (nameSorted != null) { query['name_sorted'] = nameSorted; }

        return this.request<Array<MedicalDTO>>({
            url: '/medicals/filter',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getMedical({ code }: GetMedicalRequest): Observable<MedicalDTO>
    getMedical({ code }: GetMedicalRequest, opts?: OperationOpts): Observable<RawAjaxResponse<MedicalDTO>>
    getMedical({ code }: GetMedicalRequest, opts?: OperationOpts): Observable<MedicalDTO | RawAjaxResponse<MedicalDTO>> {
        throwIfNullOrUndefined(code, 'code', 'getMedical');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<MedicalDTO>({
            url: '/medicals/{code}'.replace('{code}', encodeURI(code)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getMedicals({ sortBy }: GetMedicalsRequest): Observable<Array<MedicalDTO>>
    getMedicals({ sortBy }: GetMedicalsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<MedicalDTO>>>
    getMedicals({ sortBy }: GetMedicalsRequest, opts?: OperationOpts): Observable<Array<MedicalDTO> | RawAjaxResponse<Array<MedicalDTO>>> {

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (sortBy != null) { query['sort_by'] = sortBy; }

        return this.request<Array<MedicalDTO>>({
            url: '/medicals',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    newMedical({ medicalDTO, ignoreSimilar }: NewMedicalRequest): Observable<MedicalDTO>
    newMedical({ medicalDTO, ignoreSimilar }: NewMedicalRequest, opts?: OperationOpts): Observable<RawAjaxResponse<MedicalDTO>>
    newMedical({ medicalDTO, ignoreSimilar }: NewMedicalRequest, opts?: OperationOpts): Observable<MedicalDTO | RawAjaxResponse<MedicalDTO>> {
        throwIfNullOrUndefined(medicalDTO, 'medicalDTO', 'newMedical');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (ignoreSimilar != null) { query['ignore_similar'] = ignoreSimilar; }

        return this.request<MedicalDTO>({
            url: '/medicals',
            method: 'POST',
            headers,
            query,
            body: medicalDTO,
        }, opts?.responseOpts);
    };

    /**
     */
    updateMedical({ medicalDTO, ignoreSimilar }: UpdateMedicalRequest): Observable<MedicalDTO>
    updateMedical({ medicalDTO, ignoreSimilar }: UpdateMedicalRequest, opts?: OperationOpts): Observable<RawAjaxResponse<MedicalDTO>>
    updateMedical({ medicalDTO, ignoreSimilar }: UpdateMedicalRequest, opts?: OperationOpts): Observable<MedicalDTO | RawAjaxResponse<MedicalDTO>> {
        throwIfNullOrUndefined(medicalDTO, 'medicalDTO', 'updateMedical');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (ignoreSimilar != null) { query['ignore_similar'] = ignoreSimilar; }

        return this.request<MedicalDTO>({
            url: '/medicals',
            method: 'PUT',
            headers,
            query,
            body: medicalDTO,
        }, opts?.responseOpts);
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetMedicalsSortByEnum {
    NONE = 'NONE',
    CODE = 'CODE',
    NAME = 'NAME'
}
