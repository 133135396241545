// tslint:disable
/**
 * Open Hospital API Documentation
 * Open Hospital API Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    AgeTypeDTO,
} from '../models';

export interface GetAgeTypeByIndexRequest {
    index: number;
}

export interface GetAgeTypeCodeByAgeRequest {
    age: number;
}

export interface UpdateAgeTypeRequest {
    ageTypeDTO: Array<AgeTypeDTO>;
}

/**
 * no description
 */
export class AgeTypesApi extends BaseAPI {

    /**
     */
    getAgeTypeByIndex({ index }: GetAgeTypeByIndexRequest): Observable<AgeTypeDTO>
    getAgeTypeByIndex({ index }: GetAgeTypeByIndexRequest, opts?: OperationOpts): Observable<RawAjaxResponse<AgeTypeDTO>>
    getAgeTypeByIndex({ index }: GetAgeTypeByIndexRequest, opts?: OperationOpts): Observable<AgeTypeDTO | RawAjaxResponse<AgeTypeDTO>> {
        throwIfNullOrUndefined(index, 'index', 'getAgeTypeByIndex');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<AgeTypeDTO>({
            url: '/agetypes/{index}'.replace('{index}', encodeURI(index)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getAgeTypeCodeByAge({ age }: GetAgeTypeCodeByAgeRequest): Observable<{ [key: string]: string; }>
    getAgeTypeCodeByAge({ age }: GetAgeTypeCodeByAgeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<{ [key: string]: string; }>>
    getAgeTypeCodeByAge({ age }: GetAgeTypeCodeByAgeRequest, opts?: OperationOpts): Observable<{ [key: string]: string; } | RawAjaxResponse<{ [key: string]: string; }>> {
        throwIfNullOrUndefined(age, 'age', 'getAgeTypeCodeByAge');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'age': age,
        };

        return this.request<{ [key: string]: string; }>({
            url: '/agetypes/code',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getAllAgeTypes(): Observable<Array<AgeTypeDTO>>
    getAllAgeTypes(opts?: OperationOpts): Observable<RawAjaxResponse<Array<AgeTypeDTO>>>
    getAllAgeTypes(opts?: OperationOpts): Observable<Array<AgeTypeDTO> | RawAjaxResponse<Array<AgeTypeDTO>>> {
        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<AgeTypeDTO>>({
            url: '/agetypes',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    updateAgeType({ ageTypeDTO }: UpdateAgeTypeRequest): Observable<Array<AgeTypeDTO>>
    updateAgeType({ ageTypeDTO }: UpdateAgeTypeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<AgeTypeDTO>>>
    updateAgeType({ ageTypeDTO }: UpdateAgeTypeRequest, opts?: OperationOpts): Observable<Array<AgeTypeDTO> | RawAjaxResponse<Array<AgeTypeDTO>>> {
        throwIfNullOrUndefined(ageTypeDTO, 'ageTypeDTO', 'updateAgeType');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<AgeTypeDTO>>({
            url: '/agetypes',
            method: 'PUT',
            headers,
            body: ageTypeDTO,
        }, opts?.responseOpts);
    };

}
