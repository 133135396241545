// tslint:disable
/**
 * Open Hospital API Documentation
 * Open Hospital API Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    UserSettingDTO,
} from '../models';

export interface DeleteUserSettingRequest {
    id: number;
}

export interface GetUserSettingByUserRequest {
    configName: string;
}

export interface NewUserSettingsRequest {
    userSettingDTO: UserSettingDTO;
}

export interface UpdateUserSettingsRequest {
    id: number;
    userSettingDTO: UserSettingDTO;
}

/**
 * no description
 */
export class UserSettingsApi extends BaseAPI {

    /**
     */
    deleteUserSetting({ id }: DeleteUserSettingRequest): Observable<void>
    deleteUserSetting({ id }: DeleteUserSettingRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteUserSetting({ id }: DeleteUserSettingRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(id, 'id', 'deleteUserSetting');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<void>({
            url: '/usersettings/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getUserSettingByUser({ configName }: GetUserSettingByUserRequest): Observable<UserSettingDTO>
    getUserSettingByUser({ configName }: GetUserSettingByUserRequest, opts?: OperationOpts): Observable<RawAjaxResponse<UserSettingDTO>>
    getUserSettingByUser({ configName }: GetUserSettingByUserRequest, opts?: OperationOpts): Observable<UserSettingDTO | RawAjaxResponse<UserSettingDTO>> {
        throwIfNullOrUndefined(configName, 'configName', 'getUserSettingByUser');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<UserSettingDTO>({
            url: '/usersettings/{configName}'.replace('{configName}', encodeURI(configName)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getUserSettings(): Observable<Array<UserSettingDTO>>
    getUserSettings(opts?: OperationOpts): Observable<RawAjaxResponse<Array<UserSettingDTO>>>
    getUserSettings(opts?: OperationOpts): Observable<Array<UserSettingDTO> | RawAjaxResponse<Array<UserSettingDTO>>> {
        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<UserSettingDTO>>({
            url: '/usersettings',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    newUserSettings({ userSettingDTO }: NewUserSettingsRequest): Observable<UserSettingDTO>
    newUserSettings({ userSettingDTO }: NewUserSettingsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<UserSettingDTO>>
    newUserSettings({ userSettingDTO }: NewUserSettingsRequest, opts?: OperationOpts): Observable<UserSettingDTO | RawAjaxResponse<UserSettingDTO>> {
        throwIfNullOrUndefined(userSettingDTO, 'userSettingDTO', 'newUserSettings');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<UserSettingDTO>({
            url: '/usersettings',
            method: 'POST',
            headers,
            body: userSettingDTO,
        }, opts?.responseOpts);
    };

    /**
     */
    updateUserSettings({ id, userSettingDTO }: UpdateUserSettingsRequest): Observable<UserSettingDTO>
    updateUserSettings({ id, userSettingDTO }: UpdateUserSettingsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<UserSettingDTO>>
    updateUserSettings({ id, userSettingDTO }: UpdateUserSettingsRequest, opts?: OperationOpts): Observable<UserSettingDTO | RawAjaxResponse<UserSettingDTO>> {
        throwIfNullOrUndefined(id, 'id', 'updateUserSettings');
        throwIfNullOrUndefined(userSettingDTO, 'userSettingDTO', 'updateUserSettings');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<UserSettingDTO>({
            url: '/usersettings/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: userSettingDTO,
        }, opts?.responseOpts);
    };

}
