// tslint:disable
/**
 * Open Hospital API Documentation
 * Open Hospital API Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    InstancePreviewDTO,
    InstanceResponse,
    SeriesResponse,
    StudyResponse,
} from '../models';

export interface GetInstancePreviewRequest {
    id: string;
}

export interface GetPatientStudiesByIdRequest {
    id: string;
}

export interface GetSeriesInstancesByIdRequest {
    id: string;
}

export interface GetStudySeriesByIdRequest {
    id: string;
}

/**
 * no description
 */
export class RadiologyApi extends BaseAPI {

    /**
     */
    getInstancePreview({ id }: GetInstancePreviewRequest): Observable<InstancePreviewDTO>
    getInstancePreview({ id }: GetInstancePreviewRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InstancePreviewDTO>>
    getInstancePreview({ id }: GetInstancePreviewRequest, opts?: OperationOpts): Observable<InstancePreviewDTO | RawAjaxResponse<InstancePreviewDTO>> {
        throwIfNullOrUndefined(id, 'id', 'getInstancePreview');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<InstancePreviewDTO>({
            url: '/radiology/instances/{id}/preview'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getPatientStudiesById({ id }: GetPatientStudiesByIdRequest): Observable<Array<StudyResponse>>
    getPatientStudiesById({ id }: GetPatientStudiesByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<StudyResponse>>>
    getPatientStudiesById({ id }: GetPatientStudiesByIdRequest, opts?: OperationOpts): Observable<Array<StudyResponse> | RawAjaxResponse<Array<StudyResponse>>> {
        throwIfNullOrUndefined(id, 'id', 'getPatientStudiesById');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<StudyResponse>>({
            url: '/radiology/patients/{id}/studies'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getSeriesInstancesById({ id }: GetSeriesInstancesByIdRequest): Observable<Array<InstanceResponse>>
    getSeriesInstancesById({ id }: GetSeriesInstancesByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<InstanceResponse>>>
    getSeriesInstancesById({ id }: GetSeriesInstancesByIdRequest, opts?: OperationOpts): Observable<Array<InstanceResponse> | RawAjaxResponse<Array<InstanceResponse>>> {
        throwIfNullOrUndefined(id, 'id', 'getSeriesInstancesById');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<InstanceResponse>>({
            url: '/radiology/series/{id}/instances'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getStudySeriesById({ id }: GetStudySeriesByIdRequest): Observable<Array<SeriesResponse>>
    getStudySeriesById({ id }: GetStudySeriesByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<SeriesResponse>>>
    getStudySeriesById({ id }: GetStudySeriesByIdRequest, opts?: OperationOpts): Observable<Array<SeriesResponse> | RawAjaxResponse<Array<SeriesResponse>>> {
        throwIfNullOrUndefined(id, 'id', 'getStudySeriesById');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<SeriesResponse>>({
            url: '/radiology/studies/{id}/series'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

}
