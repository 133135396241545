// tslint:disable
/**
 * Open Hospital API Documentation
 * Open Hospital API Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    PermissionDTO,
} from '../models';

export interface RetrievePermissionByIdRequest {
    id: number;
}

export interface RetrievePermissionByNameRequest {
    name: string;
}

export interface RetrievePermissionsByUserGroupCodeRequest {
    userGroupCode: string;
}

/**
 * no description
 */
export class PermissionsApi extends BaseAPI {

    /**
     */
    retrieveAllPermissions(): Observable<Array<PermissionDTO>>
    retrieveAllPermissions(opts?: OperationOpts): Observable<RawAjaxResponse<Array<PermissionDTO>>>
    retrieveAllPermissions(opts?: OperationOpts): Observable<Array<PermissionDTO> | RawAjaxResponse<Array<PermissionDTO>>> {
        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<PermissionDTO>>({
            url: '/permissions',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    retrievePermissionById({ id }: RetrievePermissionByIdRequest): Observable<PermissionDTO>
    retrievePermissionById({ id }: RetrievePermissionByIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<PermissionDTO>>
    retrievePermissionById({ id }: RetrievePermissionByIdRequest, opts?: OperationOpts): Observable<PermissionDTO | RawAjaxResponse<PermissionDTO>> {
        throwIfNullOrUndefined(id, 'id', 'retrievePermissionById');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<PermissionDTO>({
            url: '/permissions/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    retrievePermissionByName({ name }: RetrievePermissionByNameRequest): Observable<PermissionDTO>
    retrievePermissionByName({ name }: RetrievePermissionByNameRequest, opts?: OperationOpts): Observable<RawAjaxResponse<PermissionDTO>>
    retrievePermissionByName({ name }: RetrievePermissionByNameRequest, opts?: OperationOpts): Observable<PermissionDTO | RawAjaxResponse<PermissionDTO>> {
        throwIfNullOrUndefined(name, 'name', 'retrievePermissionByName');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<PermissionDTO>({
            url: '/permissions/name/{name}'.replace('{name}', encodeURI(name)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    retrievePermissionsByUserGroupCode({ userGroupCode }: RetrievePermissionsByUserGroupCodeRequest): Observable<Array<PermissionDTO>>
    retrievePermissionsByUserGroupCode({ userGroupCode }: RetrievePermissionsByUserGroupCodeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<PermissionDTO>>>
    retrievePermissionsByUserGroupCode({ userGroupCode }: RetrievePermissionsByUserGroupCodeRequest, opts?: OperationOpts): Observable<Array<PermissionDTO> | RawAjaxResponse<Array<PermissionDTO>>> {
        throwIfNullOrUndefined(userGroupCode, 'userGroupCode', 'retrievePermissionsByUserGroupCode');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<PermissionDTO>>({
            url: '/permissions/userGroupCode/{userGroupCode}'.replace('{userGroupCode}', encodeURI(userGroupCode)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

}
