// tslint:disable
/**
 * Open Hospital API Documentation
 * Open Hospital API Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    OperationTypeDTO,
} from './';

/**
 * @export
 * @interface OperationDTO
 */
export interface OperationDTO {
    /**
     * The code of operation
     * @type {string}
     * @memberof OperationDTO
     */
    code: string;
    /**
     * The operation description
     * @type {string}
     * @memberof OperationDTO
     */
    description: string;
    /**
     * @type {OperationTypeDTO}
     * @memberof OperationDTO
     */
    type: OperationTypeDTO;
    /**
     * The operation context
     * @type {string}
     * @memberof OperationDTO
     */
    opeFor: OperationDTOOpeForEnum;
    /**
     * The operation major
     * @type {number}
     * @memberof OperationDTO
     */
    major: number;
    /**
     * Lock
     * @type {number}
     * @memberof OperationDTO
     */
    lock?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum OperationDTOOpeForEnum {
    OpdAdmission = 'opd_admission',
    Admission = 'admission',
    Opd = 'opd'
}

